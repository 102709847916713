exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-js": () => import("./../../../src/pages/advanced.js" /* webpackChunkName: "component---src-pages-advanced-js" */),
  "component---src-pages-bikes-js": () => import("./../../../src/pages/bikes.js" /* webpackChunkName: "component---src-pages-bikes-js" */),
  "component---src-pages-corratec-js": () => import("./../../../src/pages/corratec.js" /* webpackChunkName: "component---src-pages-corratec-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-service-jobrad-rechner-js": () => import("./../../../src/pages/service/jobrad-rechner.js" /* webpackChunkName: "component---src-pages-service-jobrad-rechner-js" */),
  "component---src-pages-templates-product-js": () => import("./../../../src/pages/templates/product.js" /* webpackChunkName: "component---src-pages-templates-product-js" */)
}

